<template>
  <content-section :class="getCanvasSizeClass">
    <div class="grid place-items-center">
      <img :src="getImgSrc" :class="imgClass" alt="Placeholder Illustration" />
    </div>

    <div
      class="flex flex-col items-center justify-center max-w-sm pb-10 mx-auto text-center canvas-mt"
    >
      <oto-typography variant="one" :text="title" class="block" />

      <oto-typography variant="three" :text="subtitleOne" class="block mt-6" />
      <oto-typography variant="three" :text="subtitleTwo" class="block" />
    </div>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import OtoTypography from '@/components/ui/OtoTypography'

export default {
  name: 'XPlaceholder',
  props: {
    title: {
      type: [String, Number],
      required: false,
      default: 'Under Development',
    },
    subtitleOne: {
      type: [String, Number],
      required: false,
      default: 'Hold Tight! The page / feature is being actively developed.',
    },
    subtitleTwo: {
      type: [String, Number],
      required: false,
      default: "It'll soon be alive.",
    },
    img: {
      type: String,
      required: false,
      default: 'under-dev',
      validator(val) {
        return [
          'empty-space',
          'under-dev',
          'under-development',
          'no-report',
        ].includes(val.toLowerCase())
      },
    },
    imgClass: {
      type: String,
      required: false,
      default: 'w-full h-full',
    },
    canvasSize: {
      required: false,
      default: 'auto',
    },
  },
  components: {
    ContentSection,
    OtoTypography,
  },
  computed: {
    getCanvasSizeClass() {
      let sizes = {
        auto: 'canvas-auto h-auto w-auto',
        screen: 'canvas-screen h-screen grid place-items-center',
      }
      return this.canvasSize in sizes ? sizes[this.canvasSize] : sizes['auto']
    },
    getImgSrc() {
      const imgSrcMap = {
        'empty-space': require('@/assets/placeholder/empty-space-v1-1x.png'),
        'under-dev': require('@/assets/placeholder/under-development-v1-1x.jpg'),
        'under-development': require('@/assets/placeholder/under-development-v1-1x.jpg'),
        'no-report': require('@/assets/placeholder/no-report-v1-1x.jpg'),
      }
      return imgSrcMap[this.img]
    },
  },
}
</script>

<style lang="scss" scoped>
.canvas-auto {
  .canvas-mt {
    margin-top: 2rem !important;
  }
}
.canvas-screen {
  .canvas-mt {
    margin-top: -5rem !important;
  }
}
</style>
